.calendar-desktop {
    @media (max-width: 767px){
        display: none;
    }
}
.fc-state-active{
    box-shadow: none !important;
}
.fc-scroller {
    height: auto !important;
}

.fc {
    margin: 2rem 0;
}
.fc-right .fc-button-group{
    button.fc-button {
        height: 55px;
        margin-left: 5px;
        width: 98px;
    }
}
.fc-toolbar {
    padding: 10px 0;

    h2 {
        margin-top: 10px;
        // padding-left: 50px;
        font-size: 1.5rem;
    }

    button.fc-button {
        background: $secondary;
        border: none;
        border-radius: 0;
        color: white;
        text-shadow: none;
        transition: background-color 0.3s ease;

        .fc-icon {
            display: none;
        }

        &.fc-today-button {
            text-transform: uppercase;
            height: 55px;
            padding: 0 28px;
        }

        &:hover {
            background-color: $primary;
        }

        &.fc-prev-button,
        &.fc-next-button {
            width: 100px;
            height: 55px;

            &:after {
                display: inline-block;
                transition: transform 0.3s ease;
            }
        }

        &.fc-next-button {
            &:after {
                content: $fa-var-chevron-right;
                font: normal normal normal 14px/1 FontAwesome;
            }

            &:hover {
                &:after {
                    transform: translateX(5px);
                }
            }
        }

        &.fc-prev-button {
            &:after {
                content: $fa-var-chevron-left;
                font: normal normal normal 14px/1 FontAwesome;
            }


            &:hover {
                &:after {
                    transform: translateX(-5px);
                }
            }
        }
    }
}

.fc-event,
.fc-event-dot {
    background-color: $white;
    color: $primary;
    border: 1px solid rgba($secondary, 0.1) !important;

    &:hover {
        color: $primary;
    }
}

.fc-view {
    z-index: auto;

    > table {
        z-index: auto;

        thead.fc-head {
            tr {
                border-bottom: 2px solid $secondary;
            }

            td.fc-head-container {
                border: none;

                th.fc-day-header {
                    padding: 12px 7px;
                    border-color: transparent;
                    background: $secondary;
                    color: $white;

                }
            }
        }

        tbody.fc-body {
            > tr > td.fc-widget-content {
                border: none;

                div.fc-week {
                    height: auto !important;
                    z-index: auto;

                    div.fc-bg {
                        z-index: auto;

                        > table {
                            td.fc-day {
                                background: #f9f9f9;
                                border-width: 0 1px 0 0 !important;
                                border-color: #ddd !important;
                            }
                        }
                    }

                    div.fc-content-skeleton {
                        z-index: auto;

                        > table {
                            thead {

                                .fc-day-top {
                                    padding: 5px;
                                }
                            }

                            tbody {
                                .fc-event {
                                    border: none;
                                    border-radius: 0;
                                    font-size: 14px;
                                    cursor: pointer;
                                    margin: 3px 4px;


                                    .fc-title:first-child {
                                        &:before {
                                            content: 'All Day';
                                            display: block;
                                            position: absolute;
                                            opacity: 0.5;
                                            text-transform: uppercase;
                                            font-size: 12px;
                                            display: block;
                                            width: 100%;
                                            top: 5px;
                                            left: 0;
                                            right: 0;
                                            text-align: center;
                                        }
                                    }

                                    &:hover {
                                        .fc-title {
                                            background-size: 100% 1px;
                                            background-position: 0 2.3em; // growing attached to left
                                        }
                                    }

                                    .fc-title {
                                        padding: 15px 0 25px;
                                        background-image: linear-gradient(mix($secondary, white, 30%), mix($secondary, white, 30%)); // need a background image
                                        background-size: 0% 1px;
                                        background-repeat: no-repeat;
                                        background-position: 100% 2.3em; // shrinking attached to right
                                        transition: background-size 0.25s;
                                        line-height: 18px;
                                    }

                                    .fc-content {
                                        white-space: normal;
                                        text-align: center;
                                        padding: 10px;
                                        padding-top: 23px;
                                        position: relative;
                                        z-index: 1;
                                        background: $primary;
                                        color: #fff;
                                    }

                                    .fc-time {
                                        margin-top: -15px;
                                        opacity: 0.5;
                                        text-transform: uppercase;
                                        font-size: 12px;
                                        display: block;
                                    }

                                    .fc-title {
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cal-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.3s;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .content-holder {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        max-width: 50rem;
        background: #fff;

        .content {
            width: 100%;
            max-height: 100%;
            cursor: initial;
            background: white;
            user-select: text;
            padding: 10px 0px 30px;
            box-sizing: border-box;

            p {
                font-weight: normal;
            }
            .attachment {
                padding: 12px 15px;
            }
        }

        .close {
            height: 16px;
            color: #fff;
            padding-right: 42px;
            padding: 10px;
            cursor: pointer;
            position: absolute;
            background: #00234C;
            width: 16px;
            margin: 10px;
            right: 0px;
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                right: 17px;
                top: 8px;
                height: 20px;
                width: 3px;
                background-color: #fff;
                transform: rotate(45deg);

                background-color: white;
                background-image: linear-gradient($primary, $primary);
                background-size: 100% 0%;
                background-position: 100% 0;
                background-repeat: no-repeat;
                transition: background-size 0.15s;
            }

            &:after {
                transform: rotate(135deg);
            }

            &:hover {
                &:before,
                &:after {
                    background-position: 100% 100%;
                    background-size: 100% 100%;
                }
            }
        }
        h2{
            padding: 5px 20px 20px;
            border-bottom: 2px solid #f2f2f2;
            color: #204490!important;
            -webkit-font-smoothing: antialiased;
            margin-bottom: 8px;
            font-size: 24px;
            font-weight: 600;
            line-height: 1.42857;
        }
        small,p{
            font-size: 17px!important;
            color: #000!important;
            letter-spacing: 0!important;
            line-height: 28px!important;
            padding: 0px 20px;
            margin: 5px 0px;
        }
    }
}
.digistorm-mobile-calendar .dsmcal-controls:before {
    background-color: $secondary;
}
.digistorm-mobile-calendar .dsmcal-content .dsmcal-day .dsmcal-label .dsmcal-label-total{
    background-color: $primary;
    width: 20px;
    height: 20px;
}
.digistorm-mobile-calendar .dsmcal-controls .dsmcal-monthnav{
    background-color: $primary;
}
.digistorm-mobile-calendar .dsmcal-content .dsmcal-day.dsmcal-day-today .dsmcal-label{
    background-color: rgba($secondary, 0.1) !important;
}
.digistorm-mobile-calendar .dsmcal-content .dsmcal-day .dsmcal-events .dsmcal-event, .digistorm-mobile-calendar .dsmcal-content .dsmcal-day .dsmcal-multiday-events .dsmcal-event{
    border-left: 2px solid rgba($secondary, 0.7) !important;
}
.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead{
    border-color: #ddd !important;
}

html {
    overflow:visible;
}

.cal-extra-controls {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
  
    @include mobile {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
  
  
    .button {
      span {
        z-index: 1;
      }
  
      &:hover {
        color: white;
      }
    }
  }
  
  .select-holder {
    position: relative;
  
    &:after {
      pointer-events: none;
      content: "";
      position: absolute;
      transform: translateY(-75%) rotate(45deg);
      top: 50%;
      right: 15px;
      width: 10px;
      height: 10px;
      border: 0 solid $primary;
      border-width: 0 2px 2px 0;
    }
  }
  
  select.calendar-tag-selector {
    padding: 10px 13px;
    margin-bottom: 15px;
    margin-top: 15px;
    border: 1px solid #646464;
    border-radius: 3px;
    width: 300px;
    max-width: 100%;
    font-size: 16px;
    outline: none;
    background: $white;
    appearance: none;
    font-family: "aller";
    cursor: pointer;
  
    &:hover {
      box-shadow: 0px 0px 1px 0px rgba($black, 0.3);
    }
  
    &:active,
    &:focus {
      border-color: $primary;
    }
  }
  
  .tag-filter-wrapper {
    position: relative;
    margin: 30px 0;
  
    .expand-filters {
      border: 2px solid $primary;
      margin-bottom: 10px;
      padding: 10px 20px;
      position: relative;
      padding-right: 50px;
      cursor: pointer;
      transition: background 0.3s, color 0.3s;
      min-width: 280px;
  
      .expand-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
  
        &:before, &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -8px;
          margin-top: -1px;
          width: 16px;
          height: 2px;
          background-color: $primary;
          transition: transform 0.3s, background 0.3s;
        }
  
        &:after {
          transform: rotate(90deg);
        }
      }
  
      .list-filters {
        &:before {
          content: "(";
        }
  
        &:after {
          content: ")";
        }
  
        font-size: 12px;
      }
  
      &:hover {
        background-color: $primary;
        color: white;
  
        .expand-icon {
          &:before, &:after {
            background-color: white;
          }
        }
      }
    }
  
    &.active {
      .expand-filters {
        .expand-icon {
          &:before {
            transform: rotate(180deg);
          }
  
          &:after {
            transform: rotate(180deg);
          }
        }
      }
  
      .tag-filter-block {
        max-height: 500px;
      }
    }
  }

.tag-filter-block {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 0px;
    transition: max-height .3s;
    background-color: white;
    z-index: 3;
    .button {
        margin: 0;
        min-width: 280px;
        text-align: center;
        padding: 10px;
        background-color: #b51531;
        color: white;
        &:before {
            background-color: white;
        }
        &:hover { 
            background-color: #d51531;
        }
    }
}

.calendar-tag-checkboxes {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    label {
        position: relative;
        display: flex;
        align-items: center;
        border: 2px solid $primary;
        min-width: 280px;
        padding: 10px;
        cursor: pointer;
        flex-shrink: 0;
        color: black;
        transition: background .3s;
        font-size: 14px;
        &:not(:first-child) {
            margin-top: -2px;
        }
        &:hover{ 
            background: rgba($primary, 0.1);
            &:first-child ~ label {
                background: rgba($primary, 0.1);
            }
        }
        input {
            margin-right: 10px;
        }
        .legend {
            position: absolute;
            display: block;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
        }
    }
}

.cal-modal {
    .content-holder {
        .close {
            box-sizing: content-box;
        }    
    }
}